
import { } from "../../node_modules/mmenu-light/dist/mmenu-light";
export function offcanvas() {
	document.addEventListener(
		"DOMContentLoaded", () => {
			const menu = new MmenuLight(
				document.querySelector("#navigation__menu"),
				"(max-width: 767px)"
			);
			const navigator = menu.navigation({
				"title": "WPPR"
			});
			const drawer = menu.offcanvas({
				// options
			});
			document.querySelector(".navigation__toggle")
				.addEventListener("click", (event) => {
					event.preventDefault();
					drawer.open();
				});
		}
	);
}
